@import '@purposeinplay/themes-v2/src/wildcasino.css';
@import '@purposeinplay/themes-v2/src/global.css';
@import '@purposeinplay/themes-v2/src/base.css';
@import '@purposeinplay/core-v2/dist/index.css';

:root {
  --safe-area-top-offset: calc(
    var(--tg-viewport-safe-area-inset-top, 0px) +
      var(
        --tg-viewport-content-safe-area-inset-top,
        env(safe-area-inset-top, 0px)
      )
  );
  --safe-area-right-offset: calc(
    var(--tg-viewport-safe-area-inset-right, 0px) +
      var(
        --tg-viewport-content-safe-area-inset-right,
        env(safe-area-inset-right, 0px)
      )
  );
  --safe-area-bottom-offset: max(
    6px,
    calc(
      var(--tg-viewport-safe-area-inset-bottom, 0px) +
        var(
          --tg-viewport-content-safe-area-inset-bottom,
          env(safe-area-inset-bottom, 0px)
        )
    )
  );
  --safe-area-left-offset: calc(
    var(--tg-viewport-safe-area-inset-left, 0px) +
      var(
        --tg-viewport-content-safe-area-inset-left,
        env(safe-area-inset-left, 0px)
      )
  );
}

.snackbar-wrapper {
  margin-top: var(--safe-area-top-offset);
}

/* PWA and Telegram modal paddings */
#headlessui-portal-root > div > div > div > div > div {
  padding: calc(var(--safe-area-top-offset) + 0.75rem)
    calc(var(--safe-area-right-offset) + 0.75rem)
    calc(var(--safe-area-bottom-offset) + 0.75rem)
    calc(var(--safe-area-left-offset) + 0.75rem);
}

@media (min-width: 768px) {
  #headlessui-portal-root > div > div > div > div > div {
    padding: calc(var(--safe-area-top-offset) + 1rem)
      calc(var(--safe-area-right-offset) + 1rem)
      calc(var(--safe-area-bottom-offset) + 1rem)
      calc(var(--safe-area-left-offset) + 1rem);
  }
}

@media (min-width: 1024px) {
  #headlessui-portal-root > div > div > div > div > div {
    padding: calc(var(--safe-area-top-offset) + 1.75rem)
      calc(var(--safe-area-right-offset) + 1.75rem)
      calc(var(--safe-area-bottom-offset) + 1.75rem)
      calc(var(--safe-area-left-offset) + 1.75rem);
  }
}

.safe-top-padding {
  padding-top: var(--safe-area-top-offset);
}

.safe-bottom-padding {
  padding-bottom: var(--safe-area-bottom-offset);
}

.safe-bottom-margin {
  margin-bottom: var(--safe-area-bottom-offset);
}

/* apply same focus styles to all anchor elements */
a {
  @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-base-primary;
}

.wild-transition {
  @apply transition-all duration-200 ease-in-out;
}

.tooltip-before {
  @apply before:dark:border-bgr-lightest;
}

.hide-scroll-wrapper {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Webkit-based browsers */
  }
}

.grecaptcha-badge {
  @apply invisible;
}

[data-theme='dark'] {
  background-color: #0a1c2d;
}

[data-theme='light'] {
  background-color: #ebeced;
}

.scrollbar {
  overflow-y: overlay;
}

.scrollbar::-webkit-scrollbar {
  position: absolute;
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-100 dark:bg-gray-600;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.hidescrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hidescrollbar::-webkit-scrollbar {
  display: none; /* Webkit-based browsers */
}

@media (min-width: 768px) {
  .hero-image-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: 100%;
  }
}

.after-shadow {
  @apply after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0  after:content-[''];
}

.markdown-body > div {
  font-size: 0.875em;
}

.markdown-body h1 {
  @apply mb-2 text-left text-[1.75em] font-semibold leading-6 text-text-default   last:mb-0 lg:mb-3 lg:text-left;
}
.markdown-body strong {
  @apply font-medium;
}

.markdown-body h2 {
  @apply mb-2 text-[1.3125em] font-medium text-text-default last:mb-0;
}
.markdown-body h3 {
  @apply mb-2 text-[1.09375em] font-medium text-text-default last:mb-0;
}
.markdown-body h4 {
  @apply mb-2 text-[0.875em] font-medium text-text-default last:mb-0;
}
.markdown-body h5 {
  @apply mb-2 text-[0.729em] font-medium text-text-default last:mb-0;
}
.markdown-body h6 {
  @apply mb-2 text-[0.656em] font-medium text-text-default last:mb-0;
}
.markdown-body p {
  @apply mb-4 leading-6 text-text-subdued last:mb-0;
}

.markdown-body hr {
  @apply mb-4 border-border-general-default border-opacity-20 last:mb-0;
}
.markdown-body ul {
  @apply list-disc;
}
.markdown-body ol {
  @apply list-decimal;
}
.markdown-body ul,
ol {
  @apply mb-4 px-4 last:mb-0;
}
.markdown-body li {
  @apply font-normal text-text-subdued last:mb-0;
}
.markdown-body img {
  @apply mb-4 max-h-64 object-cover;
}
.markdown-body img::before {
  z-index: 0;
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  @apply aspect-video;
}
.markdown-body a {
  @apply underline hover:text-text-default;
}
.markdown-body blockquote {
  @apply my-4 rounded-lg bg-bgr-pressed p-4;
}
.markdown-body table {
  @apply my-10 w-full border border-border-general-default last:mb-0;
}
.markdown-body th {
  @apply border
  border-surface-default px-1.5
  py-1 text-center
    text-sm font-medium uppercase tracking-wider text-text-default
  lg:px-2 lg:py-1.5;
}
.markdown-body tr {
  @apply border border-border-general-default text-text-primary text-opacity-80;
}
.markdown-body tr:nth-of-type(even) {
  @apply bg-bgr-hovered bg-opacity-40;
}
.markdown-body td {
  @apply border border-border-general-default px-1.5 py-1 text-center
  text-sm font-medium text-text-default text-opacity-80 lg:px-2 lg:py-1.5;
}
.markdown-body td:first-of-type {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.markdown-body td:last-of-type {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

@media (min-width: 768px) {
  #headlessui-portal-root div[role='dialog'] > div:nth-of-type(2) {
    padding-top: 3rem;
  }
}

#headlessui-portal-root {
  position: relative;
  z-index: 999;
}

.loyalty-image-wrapper::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 50%;
}

.input-number-container input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
}

.input-number-container input[type='number']::-webkit-outer-spin-button,
.input-number-container input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number-container input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-infinite-rotate {
  animation: rotate 2s linear infinite;
}

@keyframes scaleEffect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

.scale-animation::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  animation: scaleEffect 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  opacity: 0;
  z-index: -1;
  background: inherit;
}

.scale-animation {
  z-index: 10;
  position: relative;
  overflow: visible;
}

@media (prefers-color-scheme: dark) {
  iframe[data-intercom-frame='true'] {
    color-scheme: light;
  }
}

.link-button {
  @apply relative z-0 flex h-10 select-none items-center justify-center overflow-hidden rounded-lg 
  px-padding-2xl
  py-padding-lg !text-sm
  font-semibold outline-none transition-all
  focus:ring-0 focus:ring-offset-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed;
}

.link-button--primary {
  @apply bg-actions-primary-default text-text-on-primary hover:bg-actions-primary-hovered focus-visible:outline-focus-ring-primary disabled:bg-surface-disabled disabled:text-text-disabled hover:disabled:bg-surface-disabled hover:disabled:text-text-disabled;
}

.link-button--tertiary {
  @apply bg-surface-subdued text-text-default hover:bg-surface-hovered focus-visible:outline-border-general-default disabled:bg-surface-disabled disabled:text-text-disabled hover:disabled:bg-surface-disabled hover:disabled:text-text-disabled;
}

.link-button--tertiary-xs {
  @apply h-[1.563rem] rounded-radius-sm px-padding-lg py-padding-sm !text-xs;
}

@layer utilities {
  .hover-bg-light-opacity-50:hover {
    @apply bg-gray-200;
  }

  .hover-bg-dark-opacity-50:hover {
    background-color: rgba(26, 47, 63, 0.5);
  }
}

.link-button--tertiaryGhost {
  @apply bg-transparent text-text-default disabled:bg-transparent disabled:text-text-disabled;
}

/* Sidemenu and chat classes */
.transition-element {
  transition: transform 0.3s ease-in-out;
}

.enter-active {
  transform: translateX(0);
}

.leave-active {
  transform: translateX(-100%);
}

.leave-active-md {
  transform: translateX(-62px);
}

.tooltip-v2-arrow:before {
  @apply dark:!border-transparent;
}

[data-radix-popper-content-wrapper] {
  @apply !z-[999];
}

/* Fullscreen quad layout styles */
.fullscreen-quad-layout {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  padding: 1rem;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.fullscreen-quad-layout > div {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 0;
  overflow: hidden;
}

.fullscreen-quad-layout > div > div:nth-child(2) {
  flex: 1;
  min-height: 0;
  overflow: hidden;
}

/* Ensure the footer is always visible */
.fullscreen-quad-layout > div > div:last-child {
  position: relative;
  bottom: 0;
  width: 100%;
}
